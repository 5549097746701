.chat_sidebar {
  width: 22rem;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.chat_room {
  cursor: pointer;
  transition: all 150ms ease-in-out;
}
.chat_room:hover {
  background-color: lightgrey;
}
.chat_room_active {
  background-color: lightskyblue;
}

.chat_room_info {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 0;
  flex: 1;
}

.chat_room_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat_room_content_preview {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.new_keyword_input:focus-visible {
  outline: none;
}
